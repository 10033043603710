export interface ILoginResponse {
  status: 200;
  message: 'Token Successfully Generated.';
  data: {
    access_token: string;
    token_type: string;
    expires_in: string;
    id: string;
    accept_term: true;
    created_at: string;
  };
}

export interface ICredential {
  username: string;
  password: string;
  realmId: string;
  userType: string;
  callbackUrl: string;
}

export type ProviderType = 'oauth' | 'email' | 'credentials';

export const AUTHENTICATION_TYPE = {
  LOCAL: 'local',
  IDP: 'idp'
};
export const AUTH_TYPE_NAME = {
  Normal: 'local',
  Auth0: 'Auth0'
};

export const AccountType = {
  AUTH: 'oauth'
};

export const ErrorMessage = {
  P404: 'Principal not found.',
  SUB404: 'Sub not found.'
};
